<template>
    <v-container :key="userListKey"> 
        <div class="banner pa-5 text-center text-h3 rounded">
            班管理パネル
        </div>
        <div class="d-flex justify-center">
            <v-form v-model="formValid" style="width:500px;">
                <v-text-field outlined
                    class="mt-3"
                    type="text"
                    label="新規作成"
                    v-model="formData.teamName"
                    :rules="[v=> !!v || '必要項目です']"/>
                <v-select v-if="userList"
                    outlined
                    v-model="formData.teamLeader"
                    label="リーダー"
                    :items="filterList"
                    item-text="data.name"
                    item-value="data"
                >
                    <template v-slot:prepend-item>
                        <v-text-field 
                            class="mx-4 my-2"
                            outlined
                            prepend-inner-icon="mdi-magnify"
                            v-model="filterWord"
                        />
                    </template>
                </v-select>
                <div class="text-center">
                    <v-btn :disabled="!formValid" @click="addTeam()">作成</v-btn>
                </div>
            </v-form>
       </div>
        <v-divider class="my-3"></v-divider>
        <div class="display-1 font-weight-bold text-center">チーム一覧</div>
        
        <v-simple-table outlined>
            <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-center">
                        班名
                    </th>
                    <th class="text-center">
                        操作
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, teamIndex) in teamData" :key="teamIndex">
                    <td>{{ item.teamName }}</td>
                    <td align=center>
                        <v-btn 
                            class="white black--text" 
                            @click="memberDialogContent = {teamIndex, ...item}; memberDialogDisplay = true;"
                        >情報({{item.teamMember.length}})</v-btn>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                 <v-btn 
                                    class="white black--text ml-2" 
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteTeam(item, teamIndex)"
                                    :disabled="item.teamMember.length===0?false:true"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>{{item.teamMember.length===0?"削除":"使われているため削除できません"}}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>

        <v-divider class="my-4"/>
        
        <div class="display-1 font-weight-bold text-center ma-4">メンバー管理</div>
        <div class="text-center">
            <v-btn @click="teamMemberManagementPanelDisplay = true;">ユーザーチーム管理</v-btn>
        </div>

        <!--Dialog #1-->
        <v-dialog v-model="memberDialogDisplay" v-if="memberDialogContent" max-width="650px">
            <v-card >
                <v-container fluid fill-height>
                    <v-row align=center justify=center>
                        <v-simple-table dark outlined style="width:100%; max-width:600px;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>班名:</th>
                                        <td>{{memberDialogContent.teamName}}</td>
                                    </tr>
                                    <tr>
                                        <th>人数:</th>
                                        <td>{{memberDialogContent.teamMember.length}}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in memberDialogContent.teamMember" :key="index">
                                        <th>({{index+1}})</th>
                                        <td>
                                            {{item.name}}
                                            <span v-if="item.deleted" class="error--text">[削除済み]</span>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-center">
                                                <v-chip class="mx-1" color="success" v-if="item._id === memberDialogContent.teamLeader">
                                                    班長
                                                </v-chip>

                                                <v-tooltip top v-if="item._id !== memberDialogContent.teamLeader">
                                                    <template v-slot:activator="{ on, attrs }">  
                                                        <v-btn 
                                                            class="white black--text mx-1" 
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="updateTeamLeader(
                                                                item._id,
                                                                memberDialogContent._id,
                                                                1,
                                                                item.name,
                                                                memberDialogContent.teamIndex
                                                            )"
                                                        >
                                                            <v-icon>mdi-star</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>班長にする</span>
                                                </v-tooltip>

                                                <v-tooltip top v-if="item._id === memberDialogContent.teamLeader">
                                                    <template v-slot:activator="{ on, attrs }">  
                                                        <v-btn 
                                                            class="white black--text mx-1" 
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="updateTeamLeader(
                                                                item._id,
                                                                memberDialogContent._id,
                                                                2,
                                                                item.name,
                                                                memberDialogContent.teamIndex
                                                            )"
                                                        >
                                                            <v-icon>mdi-star-off</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>降格</span>
                                                </v-tooltip>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-row>
                </v-container>
            </v-card>
            <v-btn color="error" @click="memberDialogDisplay = false">閉じる</v-btn>
        </v-dialog>

        <v-dialog v-model="teamMemberManagementPanelDisplay" max-width="650px" :key="userListKey">
            <v-card>
                <v-container fluid fill-height>
                    <div class="display-1 font-weight-bold">メンバー移動</div>
                    <v-row align=center justify=center class="my-4">
                        <user-list 
                            :usersOnly="true"
                            :displayContent="{userTeam:true}"  
                            @syncData="syncData" 
                            :tableStyle="{width:'100%', maxWidth:'600px',}"
                            />
                    </v-row>
                    <v-row align=center justify=center>
                        <v-form v-model="formValid2" class="d-flex align-center">
                            <div>選択した項目を</div>
                            <v-select 
                                v-model="selectedTeam" 
                                label="班"
                                :rules="[v => !!v || '必須項目です']"
                                :items="teamData" 
                                item-text="teamName"
                                item-value="_id"
                            />
                            <div>に割り当てる</div>
                            
                        </v-form>
                        
                    </v-row>
                    <v-row align=center justify=center>
                        <v-btn color="success" :disabled="!formValid2" @click="updateUserTeam">確定</v-btn>
                    </v-row>
                </v-container>
            </v-card>
            <v-btn color="error" @click="teamMemberManagementPanelDisplay = false">閉じる</v-btn>
        </v-dialog>
        <!--Dialog-->

    </v-container>
</template>

<script>
import UserList from '@/components/DataTables/UserList.vue'
export default {
    components:{
        UserList
    },
    data(){
        return{
            filterWord:"",
            formValid:false,
            formValid2:false,
            teamMemberManagementPanelDisplay:false,
            memberDialogDisplay:false,
            memberDialogContent:null,
            formData : {
                teamName : "",
                teamLeader : "",
            },
            teamData : [],
            userData : null,
            selectedUser : [],
            selectedTeam : null,
            userListKey:0,
            userList:[]
        }
    },
    created(){
        this.init()
    },
    computed:{
        filterList(){
            if(this.userList.length === 0) return []
            return this.userList.filter((item)=>{
                return this.filterWord.toLowerCase().split(' ').every(v => item.data.name.toLowerCase().includes(v))
            })
        },
    },
    methods:{
        syncData(payload){
            this.selectedUser = payload
        },
        async updateUserTeam(){
            this.teamMemberManagementPanelDisplay = false
            const response = await this.$store.dispatch('post', {
                url:'api/updateUserTeam',
                rawData:{targetData:this.selectedUser, targetedTeam:this.selectedTeam},
                showUI:true
            })
            if(response && response.result){
                this.selectedUser = null
                this.init()
                this.userListKey ++
            }
        },
        async init(){
            const response = await this.$store.dispatch('get', {url:'/api/getTeamInfo', showUI:true})
            if(!response.result) return
            const isArray = Array.isArray
            const result = response.result
            if(result){
                if(isArray(result))
                    this.teamData = result
                else
                    this.teamData = [result]
            }
            this.userList = []
            this.userList.push({
                data:{
                    name:'指名なし',
                    _id:null,
                    teamName:null,
                    teamId : null,
                    deleted : false
                }     
            })
            this.teamData.forEach(team => {
                team.teamMember.forEach(member => {
                    let tmp = {
                        data: {
                            name: member.name,
                            _id : member._id,
                            teamName: team.teamName,
                            teamId : team._id,
                            deleted : member.deleted
                        }
                    }
                    this.userList = this.userList.concat(tmp)
                })
            })
        },
        async addTeam(){
            if(await this.$root.$confirm.open(
                '追加',
                '「'+this.formData.teamName+'」を新規作成しますか？',
                { color: 'success' }
            )){
                if(this.formData.teamLeader.teamName !== null){
                    if(await this.$root.$confirm.open(
                        '警告',
                        '「'+this.formData.teamLeader.name+'」様が既に配属先がありますが、「'+this.formData.teamLeader.teamName+'」から「'+this.formData.teamName+'」に移動しますか？',
                        { color: 'warning' }
                    )) {
                        const response = await this.$store.dispatch('post', {
                            url:'api/addNewTeam',
                            rawData:this.formData,
                            showUI:true
                        })
                        if(response && response.result){
                            this.init()
                        }
                    }
                }else{
                    const response = await this.$store.dispatch('post', {
                            url:'api/addNewTeam',
                            rawData:this.formData,
                            showUI:true
                        })
                        if(response && response.result)this.init()
                }
            }
        },
        async deleteTeam(targetData, teamIndex){
            if(await this.$root.$confirm.open(
                '削除',
                '「'+targetData.teamName+'」を削除しますか？',
                { color: 'error' }
            )){
                
                const response = await this.$store.dispatch('post', {
                    url:'api/deleteTeam',
                    rawData:{targetId:targetData._id},
                    showUI:true
                })
                if(response && response.result){
                    this.teamData.splice(teamIndex, 1)
                }
            }
        },
        async updateTeamLeader(targetUserId, targetTeamId, action, name, teamIndex){
            if(await this.$root.$confirm.open(
                '確認',
                '「'+name+'」を'+action===1?'班長に':'降格'+'しますか？',
                { color: 'error' }
            )){
                
                const response = await this.$store.dispatch('post', {
                    url:'api/updateTeamLeader',
                    rawData:{
                        targetUserId,
                        targetTeamId,
                        action
                    },
                    showUI:true
                })
                if(response && response.result){
                    let targetTeam = this.teamData[teamIndex]
                    if(action === 1){ //班長に　降格
                        targetTeam.teamLeader = targetUserId
                        this.memberDialogContent = {...targetTeam, teamIndex} //Need to set it again to force visual update
                    }else if(action === 2){
                        targetTeam.teamLeader = null
                        this.memberDialogContent = {...targetTeam, teamIndex} //Need to set it again to force visual update
                    }

                }
            }
        }
    }
}
</script>

<style scoped>

</style>